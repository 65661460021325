var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"FCBJCKRsPYOheWpH2bvwV"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { replayIntegration } from '@sentry/react';

Sentry.init({
    dsn: 'https://c0037c6724829090cfa426daa9e2f96e@o4507888866492416.ingest.de.sentry.io/4507888877043792',
    environment: window.env['SENTRY_ENVIRONMENT'] || 'development',
    integrations: [
        replayIntegration({
            maskAllInputs: false,
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: window.env['SENTRY_ENVIRONMENT'] === 'production' ? 1.0 : 0,
});
